import { NativeModules, Platform } from 'react-native'

const { ConfigModule } = NativeModules

// Config & ENV Types
type Env = 'debug' | 'inte' | 're7' | 'release'
type IEnvConfig = {
    visitModeId: string
    DELIVERY_LINK: string
    SERVER_PREFIX: string
    logLevel: 'debug' | 'info' | 'warn' | 'error'
}

const ENV: Env =
    (Platform.OS === 'web'
        ? (global as any).ENV
        : NativeModules.ConfigModule && (NativeModules.ConfigModule.ENV as 'debug' | 'inte' | 're7' | 'release')) ||
    'debug'

const VERSION_NUMBER = Platform.OS !== 'web' && !!ConfigModule && ConfigModule.VERSION_CODE

const VERSION_NAME =
    Platform.OS !== 'web' ? ConfigModule && (ConfigModule.VERSION_NAME || ConfigModule.VERSION) : '1.0.0'

const EnvConfig = (env: Env): IEnvConfig => {
    switch (env) {
        case 'debug':
            return {
                visitModeId: '7010194109536592138',
                DELIVERY_LINK: 'https://delivery.data-newroad.com/files/prevention/testing/android/numappee-inte-',
                SERVER_PREFIX: 'http://localhost:8080',
                logLevel: 'warn',
            }
        case 'inte':
            return {
                visitModeId: '7010194109536592138',
                DELIVERY_LINK: 'https://delivery.data-newroad.com/files/prevention/testing/android/numappee-inte-',
                SERVER_PREFIX: 'https://datanewroad-ppee-testing-api.amiltone.com',
                logLevel: 'warn',
            }
        case 're7':
            return {
                visitModeId: '7010194109536592138',
                DELIVERY_LINK: 'https://delivery.data-newroad.com/files/prevention/preprod/android/numappee-re7-',
                SERVER_PREFIX: 'https://ppee-preprodnew-api.numaprr.fr',
                logLevel: 'warn',
            }
        case 'release':
            return {
                visitModeId: '7010194109536592138',
                DELIVERY_LINK:
                    'https://delivery.data-newroad.com/files/prevention/production/android/numappee-release-',
                SERVER_PREFIX: 'https://ppee-api.numaprr.fr',
                logLevel: 'warn',
            }
        default: {
            throw Error('Unknown env : ' + ENV)
        }
    }
}

export default {
    ENV,
    VERSION_NUMBER,
    VERSION_NAME,
    ...EnvConfig(ENV),
}
